import React, {
  useState
} from 'react';

import I18n from 'i18n-js';

import {
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

import FieldError from '../../helper/FieldError';

import {
  inputId,
  inputName,
} from '../../helper/form';

const WorkflowRequirementDisclaimer = props => {

  const {
    requirement,
    configuration: {
      formName,
      defaultRequestParams,
    },
    callbacks: {
      onSetRequirement: callbackOnSetRequirement
    },
    routes: {
      indexRequirementsPath
    }
  } = props;

  const [acceptDisclaimer, setAcceptDisclaimer] = useState(false)

  const onChangeAcceptDisclaimer = event => {
    callbackOnSetRequirement(prevState => {
      return {
        ... prevState,
        accept_disclaimer: acceptDisclaimer
      }
    })
  }

  const backButton = () => {
    try {
      if(indexRequirementsPath){
        return(
          <a
            className='btn btn-default mr-2'
            href={ indexRequirementsPath(defaultRequestParams) }
          >
            { I18n.t('actions.back') }
          </a>
        )
      }
    }catch(e){}
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-material">
          <div className="card-header">
            <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
              <div className="font-weight-bold">
                { I18n.t('activerecord.attributes.workflow/process.disclaimer') }
              </div>
            </div>
          </div>

          <div className="card-body">
            <blockquote className="blockquote">
              <div
                className='overflow-auto'
                style={{ maxHeight: '750px' }}
                dangerouslySetInnerHTML={{__html: requirement.snapshot_process.disclaimer }}
              />
            </blockquote>

            <FormGroup
              check
            >
              <FieldError errors={ _.get(requirement, 'errors.accept_disclaimer') || [] }>
                <Input
                  type="checkbox"
                  id={ inputId(formName, 'accept_disclaimer') }
                  name={ inputName(formName, 'accept_disclaimer') }
                  onChange={ event => setAcceptDisclaimer(event.target.checked) }
                  checked={ acceptDisclaimer}
                  invalid={ _.has(requirement, 'errors.accept_disclaimer') }
                />

                <Label
                  check
                  htmlFor={ inputId(formName, 'accept_disclaimer') }
                  className='fw-bold required'
                >
                  { I18n.t('activerecord.attributes.workflow/requirement.accept_disclaimer') }
                </Label>
              </FieldError>
            </FormGroup>

            <FormGroup className='text-end'>
              { backButton() }
              <Button
                color='success'
                className="btn-brand-color"
                disabled={ !acceptDisclaimer }
                onClick={ event => onChangeAcceptDisclaimer(event) }
              >
                { I18n.t('actions.continue') }
              </Button>
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkflowRequirementDisclaimer
